<template>
  <div class="flex-grow">
    <div class="flex items-center justify-center h-full">
      <Form @submit="handleOtpRegis" :validation-schema="schema">
        <div class="px- m-auto pb-2 pt-1 rounded-3xl" style="max-width: 30rem; background: #63c289">
          <div class="card-body">
            <center>
              <h3>ยืนยัน</h3>
              <p>กรุณาระบุรหัส OTP ที่ส่งไปยังหมายเลข <span>xxxxxxxxxx</span></p>
              <p>หมายเลขอ้างอิง(Ref) คือ <span>xxxxxx</span></p>
            </center>

            <div class="row input">
              <div class="input-group-lg col-2">
                <Field name="input_1" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_1" class="error-feedback" />
              </div>
              <div class="input-group-lg col-2">
                <Field name="input_2" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_2" class="error-feedback" />
              </div>
              <div class="input-group-lg col-2">
                <Field name="input_3" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_3" class="error-feedback" />
              </div>
              <div class="input-group-lg col-2">
                <Field name="input_4" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_4" class="error-feedback" />
              </div>
              <div class="input-group-lg col-2">
                <Field name="input_5" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_5" class="error-feedback" />
              </div>
              <div class="input-group-lg col-2">
                <Field name="input_6" type="text" class="form-control" placeholder="X" maxlength="1" />
                <ErrorMessage name="input_6" class="error-feedback" />
              </div>
            </div>
            <button type="reset" style="float: right">ล้างตัวเลข</button>
            <div class="col-sm-12">
              <button class="btn confirm col-sm-12" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span>ยืนยันตัวตน</span>
              </button>
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
              <button class="btn btn-secondary col-sm-12" style="margin-bottom: 1rem" @click="getOTP" id="otp"><span id="textCount">ขอ OTP อีกครั้ง</span></button>
              <router-link to="/getotp" id="edit-number">แก้ไขเบอร์โทรศัพท์</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

export default {
  name: 'otp',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      input_1: yup.string().required('required!'),
      input_2: yup.string().required('required!'),
      input_3: yup.string().required('required!'),
      input_4: yup.string().required('required!'),
      input_5: yup.string().required('required!'),
      input_6: yup.string().required('required!')
    })

    return {
      loading: false,
      message: '',
      schema
    }
  },
  methods: {
    handleOtpRegis(user) {
      this.loading = true

      this.$store.dispatch('auth/confirmotpregister', user).then(
        () => {
          this.$router.push('/login')
        },
        (error) => {
          this.loading = false
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    },
    getOTP() {
      this.$store.dispatch('auth/getOTP').then(
        () => {
          this.$router.push('/confirmotp')
          this.countDown()
        },
        (error) => {
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        }
      )
    },
    countDown() {
      var timeleft = 10
      var downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer)
          document.getElementById('textCount').innerHTML = 'ขอ OTP อีกครั้ง'
          document.getElementById('otp').disabled = false
        } else {
          document.getElementById('textCount').innerHTML = 'ขอ OTP อีกครั้งในอีก ' + timeleft + ' วินาที'
          document.getElementById('otp').disabled = true
        }
        timeleft -= 1
      }, 1000)
    }
  },
  async created() {
    var timeleft = 10
    var downloadTimer = setInterval(function () {
      if (timeleft <= 0) {
        clearInterval(downloadTimer)
        document.getElementById('textCount').innerHTML = 'ขอ OTP อีกครั้ง'
        document.getElementById('otp').disabled = false
      } else {
        document.getElementById('textCount').innerHTML = 'ขอ OTP อีกครั้งในอีก ' + timeleft + ' วินาที'
        document.getElementById('otp').disabled = true
      }
      timeleft -= 1
    }, 1000)
  }
}
</script>

<style scoped>
h3,
span,
#textCount {
  color: #fff;
}
button span {
  color: black;
}
.confirm {
  background-color: #fff;
  margin: 15px auto;
}
.input {
  display: flex;
  justify-content: center;
}
.input input {
  text-align: center;
}
#edit-number {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: #fff;
}
</style>
